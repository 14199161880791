<template>
  <div>
    <v-card elevation="0">
      <v-card-text>
        <v-card>
          <v-card-text class="text-center">
            <vac :left-time="leftTime" :auto-start="false" ref="totalTimer">
              <span slot="before">
                <div class="text-h3">
                  {{ timer.min | doubleDigits }}:{{
                    timer.sec | doubleDigits
                  }}
                  / {{ timer.min | doubleDigits }}:{{
                    timer.sec | doubleDigits
                  }}
                </div>
              </span>
              <span slot="process" slot-scope="{ timeObj }">
                <div class="text-h3">
                  {{ timeObj.m }}:{{ timeObj.s }} /
                  {{ timer.min | doubleDigits }}:{{ timer.sec | doubleDigits }}
                </div>
              </span>
              <span slot="finish">
                <div class="text-h3 red--text">
                  <span class="blinking"
                    >00:00/{{ timer.min | doubleDigits }}:{{
                      timer.sec | doubleDigits
                    }}</span
                  >
                </div>
              </span>
            </vac>
            <vac :left-time="indLeftTime" :auto-start="false" ref="vac2">
              <span slot="before">
                <div class="text-h1">
                  {{ indTimer.min | doubleDigits }}:{{
                    indTimer.sec | doubleDigits
                  }}
                  / {{ indTimer.min | doubleDigits }}:{{
                    indTimer.sec | doubleDigits
                  }}
                </div>
              </span>
              <span slot="process" slot-scope="{ timeObj }">
                <div class="text-h1">
                  {{ timeObj.m }}:{{ timeObj.s }} /
                  {{ indTimer.min | doubleDigits }}:{{
                    indTimer.sec | doubleDigits
                  }}
                </div>
              </span>
              <span slot="finish">
                <div class="text-h1 red--text">
                  <span class="blinking"
                    >00:00/{{ indTimer.min | doubleDigits }}:{{
                      indTimer.sec | doubleDigits
                    }}</span
                  >
                </div>
              </span>
            </vac>
          </v-card-text>
        </v-card>
        <v-card-actions class="justify-center">
          <v-row>
            <v-col md="6">
              <v-btn color="secondary" block @click="switchCountdown">
                <v-icon>{{ timerIcon }}</v-icon>
                {{ timerText }}
              </v-btn>
            </v-col>
            <v-col md="6">
              <v-btn color="secondary" block @click="resetCountdown">
                <v-icon>mdi-restore</v-icon>{{ $t("restart") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "mod-timer",
  props: ["durationMin", "durationSec", "indDurationMin", "indDurationSec"],
  filters: {
    doubleDigits: function (val) {
      const formattedNumber = ("0" + val).slice(-2);
      return formattedNumber;
    },
  },
  data() {
    return {
      dialog: false,
      valid: true,
      timerIcon: "mdi-play",
      timerText: "",
      leftTime: 600000,
      indLeftTime: 60000,
      timer: {
        min: 1,
        sec: 0,
      },
      indTimer: {
        min: 10,
        sec: 0,
      },
    };
  },
  methods: {
    resetCountdown() {
      const vm = this;
      vm.$refs.vac2.startCountdown(true);
      vm.$refs.totalTimer.startCountdown();
    },
    continueCountdown() {
      const vm = this;
      vm.$refs.vac2.startCountdown();
      vm.$refs.totalTimer.startCountdown();
    },
    stopCountdown() {
      const vm = this;
      vm.$refs.vac2.pauseCountdown();
      vm.$refs.totalTimer.pauseCountdown();
    },
    switchCountdown() {
      console.log(this.$refs.vac2.state);
      const vm = this;
      if (
        vm.$refs.vac2.state === "paused" ||
        vm.$refs.vac2.state === "beforeStart"
      ) {
        vm.$refs.vac2.startCountdown();
        vm.$refs.totalTimer.startCountdown();
      } else {
        vm.$refs.totalTimer.pauseCountdown();
        vm.$refs.vac2.pauseCountdown();
      }
      vm.$nextTick(() => {
        vm.timerIcon =
          vm.$refs.vac2.state === "paused" ? "mdi-play" : "mdi-stop";
        vm.timerText =
          vm.$refs.vac2.state === "paused"
            ? this.$t("continue")
            : this.$t("pause");
      });
    },
    setTimer() {
      this.leftTime = this.timer.min * 60000 + this.timer.sec * 1000;
      this.dialog = false;
      console.log(this.leftTime);
    },
  },
  mounted() {
    this.timerText = this.$t("continue");
    this.$watch(
      () => {
        return this.$refs.vac2.state;
      },
      (val) => {
        console.log(val);
        if (val === "finished") {
          this.$refs.totalTimer.pauseCountdown();
        }
        if (val === "process") {
          this.timerIcon = "mdi-stop";
          this.timerText = this.$t("pause");
        }
      }
    );
  },
  created() {
    this.indLeftTime = 1000 * this.indDurationSec + 60000 * this.indDurationMin;
    this.leftTime = 1000 * this.durationSec + 60000 * this.durationMin;
    this.timer = {
      min: this.durationMin,
      sec: this.durationSec,
    };
    this.indTimer = {
      min: this.indDurationMin,
      sec: this.indDurationSec,
    };
  },
};
</script>

<style scoped>
.blinking {
  animation: blinkingText 1.2s infinite;
}
@keyframes blinkingText {
  0% {
    color: #d32f2f;
  }
  49% {
    color: #d32f2f;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #d32f2f;
  }
}
</style>

<i18n>
{
  "en": {
    "restart": "Restart",
    "speakingTime": "Speaking Time",
    "min": "Minute",
    "sec": "Second",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "continue": "Continue",
    "pause": "Pause"
  
  },
  "zh": {
    "restart": "重新开始",
    "speakingTime": "发言时长",
    "sec": "秒",
    "min": "分钟",
    "cancel": "取消",
    "confirm": "确定",
    "continue": "继续",
    "pause": "暂停"
  }
}
</i18n>
<template>
  <div>
    <v-row>
      <v-col cols="8">
        <v-card>
          <v-card-title class="text-h4 font-weight-bold justify-center"
            >{{ $t("topic") }}: {{ mod && mod.topic }}</v-card-title
          >
          <mod-timer
            :durationMin="mod.durationMin"
            :durationSec="mod.durationSec"
            :indDurationMin="mod.indDurationMin"
            :indDurationSec="mod.indDurationSec"
            :restartTimer="restartTimer"
          ></mod-timer>
          <v-card-title class="text-subtitle-1">
            {{ $t("currentSpeaker") }}
            <v-spacer></v-spacer>
            <v-btn
              class="ml-12"
              color="primary"
              outlined
              @click="nextSpeaker()"
              :disabled="isEmpty"
            >
              {{ $t("next") }}</v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-list>
              <div v-if="speakingList[0]">
                <v-list-item>
                  <v-list-item-avatar>
                    <img
                      :src="getImage(speakingList[0] && speakingList[0].code)"
                      :alt="speakingList[0].name_en"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="justify-center">
                      <span class="text-h6"
                        >{{ speakingList[0].name_zh }}
                        {{ speakingList[0].name_en }}</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
            <v-divider></v-divider>
            <div class="ma-3">
              <div>{{ $t("speakerList") }}</div>
              <v-container style="max-height: 500px" class="overflow-y-auto">
                <v-list>
                  <v-list-item
                    v-for="(item, index) in speakingList.slice(1)"
                    :key="index"
                  >
                    <v-list-item-avatar>
                      <img
                        :src="getImage(item && item.code)"
                        alt="item.name_en"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="$root.$i18n.locale === 'zh'">
                          {{ item.name_zh }}
                        </span>
                        {{ item.name_en }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="removeSpeakingList(item)">
                        <v-icon color="red lighten-1">mdi-close-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-container>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-text-field
          outlined
          v-model="search"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <span v-if="reachMax">{{ $t("reachMax") }}</span>
        <v-divider></v-divider>
        <v-container style="max-height: 800px" class="overflow-y-auto">
          <div v-for="(item, index) in countriesPresentFiltered" :key="index">
            <div
              v-if="inSpeakingList(item) || reachMax"
              style="background: #e0e0e0"
            >
              <v-list-item>
                <v-list-item-avatar>
                  <img :src="getImage(item.code)" alt="item.name_en" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <span v-if="$root.$i18n.locale === 'zh'">
                    {{ item.name_zh }}
                  </span>
                  {{ item.name_en }}</v-list-item-content
                >
              </v-list-item>
            </div>
            <div v-else>
              <v-list-item @click="addToSpeakingList(item)">
                <v-list-item-avatar>
                  <img :src="getImage(item.code)" alt="item.name_en" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <span v-if="$root.$i18n.locale === 'zh'">
                    {{ item.name_zh }} </span
                  >{{ item.name_en }}</v-list-item-content
                >
              </v-list-item>
            </div>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import modTimer from "../components/modTimer";

export default {
  metaInfo() {
    return {
      title: this.$t("mod"),
    };
  },
  props: {},
  components: {
    modTimer,
  },
  data() {
    return {
      speakers: [],
      search: "",
      time: 60000,
      speakingList: [],
      restartTimer: false,
      countriesPresentFiltered: this.countriesPresent,
    };
  },
  watch: {
    search(val) {
      this.filterCountriesPresent(val);
    },
  },
  computed: {
    reachMax() {
      const max = Math.floor(
        (parseInt(this.mod.durationMin) * 60 + parseInt(this.mod.durationSec)) /
          (parseInt(this.mod.indDurationMin) * 60 +
            parseInt(this.mod.indDurationSec))
      );
      // const max =
      //   (this.mod.durationMin * 60 + this.mod.durationSec) /
      //   (this.mod.indDurationMin * 60 );
      console.log(max);
      let flag = false;
      if (this.speakingList.length >= max) {
        flag = true;
      }
      return flag;
    },
    mod() {
      return this.$store.state.currentSession.currentMod;
    },
    isEmpty() {
      var len = this.speakingList.length;
      return len === 0;
    },
    countriesPresent: {
      cache: false, //每次访问都更新取值，
      get: function () {
        return this.$store.getters.inSessionCountriesPresent;
      },
    },
  },
  methods: {
    filterCountriesPresent(val) {
      const text = val.toLowerCase();
      const arr = this.countriesPresent.filter(
        (x) =>
          x.code.toLowerCase().includes(text) ||
          x.name_zh.toLowerCase().includes(text) ||
          x.name_en.toLowerCase().includes(text)
      );
      this.countriesPresentFiltered = arr;
    },
    getImage(code) {
      if (code.length > 3) {
        return require(`../assets/flags/UN.svg`);
      } else {
        return require(`../assets/flags/${code}.svg`);
      }
    },
    inSpeakingList(item) {
      return this.speakingList.includes(item);
    },
    addToSpeakingList(item) {
      this.speakingList.push(item);
      let indexSession = this.$store.state.currentSession.inSessionCountries.findIndex(
        (x) => x.code === item.code
      );
      this.$store.state.currentSession.inSessionCountries[
        indexSession
      ].on_mod = true;
    },
    removeSpeakingList(item) {
      let index = this.speakingList.findIndex((x) => x.code === item.code);
      let indexSession = this.$store.state.currentSession.inSessionCountries.findIndex(
        (x) => x.code === item.code
      );
      this.$store.state.currentSession.inSessionCountries[
        indexSession
      ].on_mod = false;
      this.speakingList.splice(index, 1);
    },
    nextSpeaker() {
      if (this.speakingList.length > 0) {
        this.speakingList.splice(0, 1);
        this.restartTimer = true;
        let index = this.$store.state.currentSession.inSessionCountries.findIndex(
          (x) => x.code === this.speakingList[0].code
        );
        this.$store.state.currentSession.inSessionCountries[
          index
        ].on_mod = false;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.speakingList.length === 0) {
      next();
    } else {
      const answer = window.confirm("确认离开么？磋商内容将重置");
      if (answer) {
        next();
      } else {
        next(false);
      }
    }
  },
};
</script>


<i18n>
{
  "en": {
    "currentMotion": "Current Motion",
    "currentSpeaker": "Current Speaker",
    "speakerList": "Speaker List",
    "motion": "Motion",
    "mod": "Moderated Caucus",
    "unmod": "Unmoderated Caucus",
    "topic": "Topic",
    "totalTime": "Total Time",
    "next": "Next",
    "min": "Minute",
    "sec": "Second",
    "confirm": "Confirm",
    "continue": "Continue",
    "pass": "Pass",
    "everyDelegate": "Each Delegate",
    "fail": "Fail",
    "noMotion": "No Motion, Please Add New Ones",
    "clearAll": "Clear All",
    "reachMax": "Max numbers of countries selected",
    "newMotion": "New Motion",
    "submittingCountry": "Submitting Country",
    "input60": "Please Input Integer within 60 ",
    "pause": "Pause"

  },
  "zh": {
    "currentSpeaker": "当前发言",
    "speakerList": "发言名单",
    "motion": "动议",
    "mod": "有组织核心磋商",
    "unmod": "无组织核心磋商",
    "topic": "主题",
    "totalTime": "总时长",
    "next": "下一位",
    "min": "分钟",
    "sec": "秒",
    "confirm": "确定",
    "everyDelegate": "每位代表",
    "pass": "通过",
    "noMotion": "尚无动议",
    "fail": "未通过",
    "continue": "继续",
    "clearAll": "全部取消",
    "newMotion": "新增动议",
    "submittingCountry": "动议国",
    "reachMax": "发言国家数量已达上限",
    "input60": "输入60以内整数",
    "pause": "暂停"
  }
}
</i18n>